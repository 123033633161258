<template>
  <div>
    <device-info-dialog
      v-if="dialogs.info"
      :deviceId="deviceId"
      :open="dialogs.info"
      @close="closeDialog('info')"
    />
    <device-settings-dialog
      v-if="dialogs.settings"
      :deviceId="deviceId"
      :permissions="permissions"
      :open="dialogs.settings"
      @close="closeDialog('settings')"
    />
    <device-alerts-dialog
      v-if="dialogs.alerts"
      :deviceId="deviceId"
      :permissions="permissions"
      :open="dialogs.alerts"
      @close="closeDialog('alerts')"
    />
    <device-sharing-dialog
      v-if="dialogs.sharing"
      :deviceId="deviceId"
      :permissions="permissions"
      :open="dialogs.sharing"
      @close="closeDialog('sharing')"
    />
    <history-dialog
      v-if="dialogs.history"
      :currentDeviceId="deviceId"
      :isDetachedDialog="false"
      :removableDevices="false"
      :open="dialogs.history"
      @close="closeDialog('history')"
    />
  </div>
</template>

<script>
import DeviceInfoDialog from '../dialogs/deviceInfo/DeviceInfoDialog.vue'
import DeviceSettingsDialog from '../dialogs/DeviceSettingsDialog.vue'
import DeviceSharingDialog from '../dialogs/DeviceSharingDialog.vue'
import DeviceAlertsDialog from '../dialogs/DeviceAlertsDialog.vue'
import HistoryDialog from '../dialogs/HistoryDialog.vue'
import { getPermissionsByDeviceId } from '../../services/subscriptions'

export default {
  name: 'DeviceActionMenuDialogs',
  props: {
    deviceId: String,
    dialog: String,
  },
  data() {
    return {
      dialogs: {
        info: false,
        settings: false,
        alerts: false,
        sharing: false,
        history: false,
      },
    }
  },
  components: {
    'device-info-dialog': DeviceInfoDialog,
    'device-settings-dialog': DeviceSettingsDialog,
    'device-sharing-dialog': DeviceSharingDialog,
    'device-alerts-dialog': DeviceAlertsDialog,
    'history-dialog': HistoryDialog,
  },
  mounted() {
    this.openDialog()
  },
  watch: {
    dialog(dialog) {
      if (dialog) {
        this.openDialog()
      }
    },
  },
  methods: {
    openDialog() {
      if (this.dialog && this.deviceId) {
        this.dialogs[this.dialog] = true
      }
    },
    closeDialog(dialog) {
      this.dialogs[dialog] = false
      this.$emit('closeDialog')
    },
  },
  computed: {
    permissions() {
      return getPermissionsByDeviceId(this.deviceId)
    },
  },
}
</script>
