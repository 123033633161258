<template>
  <div class="popover-toolbar">
    <eol-alert :deviceId="deviceId" />
    <connection-status :deviceId="deviceId" />
    <device-alerts :deviceId="deviceId" />
    <device-notifications :deviceId="deviceId" :deviceName="name" />
  </div>
</template>

<script>
import eolAlert from './eolAlert.vue'
import connectionStatus from './connectionStatus.vue'
import deviceAlerts from './deviceAlerts.vue'
import deviceNotifications from './deviceNotifications.vue'

export default {
  components: {
    'device-alerts': deviceAlerts,
    'device-notifications': deviceNotifications,
    'eol-alert': eolAlert,
    'connection-status': connectionStatus,
  },
  props: {
    deviceId: String,
  },
  data() {
    return {}
  },
  computed: {
    name: function () {
      return this.$store.getters['devices/getNameByDeviceId'](this.deviceId)
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.popover-toolbar {
  white-space: nowrap;
}
</style>
