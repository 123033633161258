import { replaceData, deleteData } from '../utils'
import {
  isDeviceConnected,
  getDeviceSubscriptionType,
  getLastDeviceCommunicationTimestamp,
  getLastConnectedDeviceCommunicationTimestamp,
} from '../../services/device-status'

export default {
  setAllDevices(state, devices) {
    for (const deviceId in devices) {
      const device = devices[deviceId]
      state.allDevices = replaceData(state.allDevices, deviceId, device)
    }
  },
  setAllUserDevices(state, userDevices) {
    for (const deviceId in userDevices) {
      const device = userDevices[deviceId]
      state.allUserDevices = replaceData(state.allUserDevices, deviceId, device)
    }

    // find any devices that are currently in the user device state that are not included in the latest api response
    const missingDevices = Object.keys(state.allUserDevices).filter((id) => !Object.keys(userDevices).includes(id))
    for (const deviceId of missingDevices) {
      state.allUserDevices = deleteData(state.allUserDevices, deviceId)
    }
  },
  setDevices(state, devices) {
    for (const deviceId in devices) {
      state.accountId = replaceData(
        state.accountId,
        deviceId,
        devices[deviceId]['accountId']
      )
      if (devices[deviceId].coords) {
        state.coords = replaceData(state.coords, deviceId, {
          lng: devices[deviceId].coords['lng'],
          lat: devices[deviceId].coords['lat'],
        })
      }
      state.model = replaceData(
        state.model,
        deviceId,
        devices[deviceId]['model']
      )
      state.submodel = replaceData(
        state.submodel,
        deviceId,
        devices[deviceId]['submodel']
      )
      state.serial = replaceData(
        state.serial,
        deviceId,
        devices[deviceId]['serial']
      )
      state.indoor = replaceData(
        state.indoor,
        deviceId,
        devices[deviceId]['indoor']
      )
      state.name = replaceData(state.name, deviceId, devices[deviceId]['name'])
      state.locationName = replaceData(state.locationName, deviceId, devices[deviceId]['locationName'])
      state.public = replaceData(
        state.public,
        deviceId,
        devices[deviceId]['isPublic']
      )
      state.shared = replaceData(
        state.shared,
        deviceId,
        devices[deviceId]['isShared']
      )
      state.privacyZone = replaceData(
        state.privacyZone,
        deviceId,
        devices[deviceId]['privacyZone']
      )
      state.lastTelemetry = replaceData(
        state.lastTelemetry,
        deviceId,
        devices[deviceId]['timestamp']
      )
      state.sensorReadings = replaceData(
        state.sensorReadings,
        deviceId,
        devices[deviceId]['readings']
      )
      state.sensorStatus = replaceData(
        state.sensorStatus,
        deviceId,
        devices[deviceId]['sensorStatus']
      )
      state.deviceStatus = replaceData(
        state.deviceStatus,
        deviceId,
        devices[deviceId]['deviceStatus']
      )
    }
  },
  setUserDevices(state, devices) {
    for (const deviceId in devices) {
      state.serial = replaceData(
        state.serial,
        deviceId,
        devices[deviceId].serial
      )
      state.accountId = replaceData(
        state.accountId,
        deviceId,
        devices[deviceId].cloud_account_id
      )
      state.name = replaceData(
        state.name,
        deviceId,
        devices[deviceId].name
      )
      state.locationName = replaceData(
        state.locationName,
        deviceId,
        devices[deviceId].location_name
      )
      state.model = replaceData(
        state.model,
        deviceId,
        devices[deviceId].model
      )
      state.submodel = replaceData(
        state.submodel,
        deviceId,
        parseInt(devices[deviceId].sub_model) ? `${devices[deviceId].sub_model} gases` : ''
      )
      state.shared = replaceData(
        state.shared,
        deviceId,
        devices[deviceId].is_shared
      )
      state.public = replaceData(
        state.public,
        deviceId,
        devices[deviceId].is_public
      )
      state.indoor = replaceData(
        state.indoor,
        deviceId,
        devices[deviceId].is_indoor
      )
      state.coords = replaceData(state.coords, deviceId, {
        lng: devices[deviceId].longitude,
        lat: devices[deviceId].latitude,
      })
      state.firmware = replaceData(
        state.firmware,
        deviceId,
        devices[deviceId].firmware_version
      )
      state.rssi = replaceData(
        state.rssi,
        deviceId,
        devices[deviceId].ap_rssi
      )
      state.connectionType = replaceData(
        state.connectionType,
        deviceId,
        devices[deviceId].connection_type
      )
      state.lastTelemetry = replaceData(
        state.lastTelemetry,
        deviceId,
        devices[deviceId].latest_timestamp
      )
      state.sensorEoL = replaceData(
        state.sensorEoL,
        deviceId,
        devices[deviceId].sps_eol
      )
      state.deviceStatus = replaceData(
        state.deviceStatus,
        deviceId,
        devices[deviceId].system_status_messages
      )
      state.sensorStatus = replaceData(
        state.sensorStatus,
        deviceId,
        devices[deviceId].sensors_status_messages
      )
      state.measurements = replaceData(
        state.measurements,
        deviceId,
        devices[deviceId].measurements
      )
      state.subscriptionType = replaceData(
        state.subscriptionType,
        deviceId,
        getDeviceSubscriptionType(devices[deviceId])
      )
      state.connectedSmartBridgeModel = replaceData(
        state.connectedSmartBridgeModel,
        deviceId,
        devices[deviceId].gateway_model
      )
      state.connectedSmartBridgeSerial = replaceData(
        state.connectedSmartBridgeSerial,
        deviceId,
        devices[deviceId].gateway_serial
      )

      // If the device is connected to smart bridge the last communication will be the last telemetry or last state timestamp.
      // Otherwise, the last communication will be the most recent of any of the 3 timestamps in the iot_core_data object.
      const lastCommunicationTime = devices[deviceId].gateway_serial
        ? getLastConnectedDeviceCommunicationTimestamp(
            devices[deviceId].latest_timestamp,
            devices[deviceId].latest_state_timestamp
          )
        : getLastDeviceCommunicationTimestamp(devices[deviceId].iot_core_data)

      state.lastCommunicationTime = replaceData(
        state.lastCommunicationTime,
        deviceId,
        lastCommunicationTime
      )

      const isConnected = devices[deviceId].gateway_serial ? devices[deviceId].connected : isDeviceConnected(lastCommunicationTime)
      state.isConnected = replaceData(
        state.isConnected,
        deviceId,
        isConnected
      )

      state.telemTxType = replaceData(
        state.telemTxType,
        deviceId,
        devices[deviceId].telem_tx_type
      )
      state.batteryCharging = replaceData(
        state.batteryCharging,
        deviceId,
        devices[deviceId].battery_charging
      )
      state.batteryInstalled = replaceData(
        state.batteryInstalled,
        deviceId,
        devices[deviceId].battery_installed
      )
      state.batteryLow = replaceData(
        state.batteryLow,
        deviceId,
        devices[deviceId].battery_low
      )
      state.batteryRemaining = replaceData(
        state.batteryRemaining,
        deviceId,
        devices[deviceId].battery_remaining
      )
      if (devices[deviceId].upload_status) {
        state.uploadStatus = replaceData(
          state.uploadStatus,
          deviceId,
          devices[deviceId].upload_status
        )
      }
    }
  },
  setDevice(state, device) {
    state.accountId = replaceData(
      state.accountId,
      device.deviceId,
      device['accountId']
    )
    if (device.coords) {
      state.coords = replaceData(state.coords, device.deviceId, {
        lng: device.coords['lng'],
        lat: device.coords['lat'],
      })
    }
    state.model = replaceData(state.model, device.deviceId, device['model'])
    state.submodel = replaceData(
      state.submodel,
      device.deviceId,
      device['submodel']
    )
    state.serial = replaceData(state.serial, device.deviceId, device['serial'])
    state.indoor = replaceData(state.indoor, device.deviceId, device['indoor'])
    state.name = replaceData(state.name, device.deviceId, device['name'])
    state.locationName = replaceData(state.locationName, device.deviceId, device['locationName'])
    state.public = replaceData(
      state.public,
      device.deviceId,
      device['isPublic']
    )
    state.shared = replaceData(
      state.shared,
      device.deviceId,
      device['isShared']
    )
    state.privacyZone = replaceData(
      state.privacyZone,
      device.deviceId,
      device['privacyZone']
    )
    state.lastTelemetry = replaceData(
      state.lastTelemetry,
      device.deviceId,
      device['timestamp']
    )
    state.sensorReadings = replaceData(
      state.sensorReadings,
      device.deviceId,
      device['readings']
    )
  },
  updateCoords(state, data) {
    state.coords = replaceData(state.coords, data.deviceId, {
      lng: data['coords']['lng'],
      lat: data['coords']['lat'],
    })
  },
  updateSensorReadings(state, data) {
    state.sensorReadings = replaceData(
      state.sensorReadings,
      data.deviceId,
      data['measurements']
    )
  },
  updateDeviceStatus(state, data) {
    state.deviceStatus = replaceData(
      state.deviceStatus,
      data.deviceId,
      data.deviceStatus
    )
  },
  updateSensorStatus(state, data) {
    state.sensorStatus = replaceData(
      state.sensorStatus,
      data.deviceId,
      data.sensorStatus
    )
  },
  updateRSSI(state, data) {
    state.rssi = replaceData(state.rssi, data.deviceId, data['rssi'])
  },
  updateConnectionType(state, data) {
    state.connectionType = replaceData(state.connectionType, data.deviceId, data['connectionType'])
  },
  updateEoL(state, data) {
    state.sensorEoL = replaceData(
      state.sensorEoL,
      data.deviceId,
      data['spsEoL']
    )
  },
  updateUserCals(state, data) {
    state.userCals = replaceData(
      state.userCals,
      data.deviceId,
      data['userCals']
    )
  },
  updateFirmware(state, data) {
    state.firmware = replaceData(
      state.firmware,
      data.deviceId,
      data['firmware']
    )
  },
  updateAverages(state, data) {
    state.averages = replaceData(
      state.averages,
      data.deviceId,
      data['averages']
    )
  },
  updatePercentChanged(state, data) {
    state.percentChanged = replaceData(
      state.percentChanged,
      data.deviceId,
      data['percentChanged']
    )
  },
  updatePublic(state, data) {
    state.public = replaceData(state.public, data.deviceId, data['isPublic'])
  },
  updatePrivacyZone(state, data) {
    state.privacyZone = replaceData(
      state.privacyZone,
      data.deviceId,
      data['privacyZone']
    )
  },
  updateLastTelemetry(state, data) {
    state.lastTelemetry = replaceData(
      state.lastTelemetry,
      data.deviceId,
      data['timestamp']
    )
  },
  updateLastState(state, data) {
    state.lastState = replaceData(
      state.lastState,
      data.deviceId,
      data['timestamp']
    )
  },
  updateTelemTxType(state, data) {
    state.telemTxType = replaceData(
      state.telemTxType,
      data.deviceId,
      data.telemTxType
    )
  },
  updateIsConnected(state, data) {
    state.isConnected = replaceData(
      state.isConnected,
      data.deviceId,
      data.isConnected
    )
  },
  updateIndoor(state, data) {
    state.indoor = replaceData(state.indoor, data.deviceId, data['indoor'])
  },
  updateName(state, data) {
    state.name = replaceData(state.name, data.deviceId, data['name'])
  },
  updateLocationName(state, data) {
    state.locationName = replaceData(state.locationName, data.deviceId, data['locationName'])
  },
  updateModel(state, data) {
    state.model = replaceData(state.model, data.deviceId, data['model'])
  },
  updateZeroAppliedAt(state, data) {
    state.zeroAppliedAt = replaceData(state.zeroAppliedAt, data.deviceId, data['zeroAppliedAt'])
  },
  updateZeroState(state, data) {
    state.zeroState = replaceData(state.zeroState, data.deviceId, data['zeroState'])
  },
  updateSubmodel(state, data) {
    state.submodel = replaceData(
      state.submodel,
      data.deviceId,
      data['submodel']
    )
  },
  updateSerial(state, data) {
    state.serial = replaceData(state.serial, data.deviceId, data['serial'])
  },
  updateShared(state, data) {
    state.shared = replaceData(state.shared, data.deviceId, data['isShared'])
  },
  deleteDeviceById(state, id) {
    for (let key in state) {
      if (key === 'allUserDevices') {
        deleteData(state[key], id)
        continue
      }
      state[key][id] = undefined
    }
  },
  setSubscriptionMetadata(state, data) {
    for (const device of data) {
      const { deviceId, activation } = device
      if (activation?.website || activation?.dataservices || activation?.permissions) {
        state.subscriptionMetadata = replaceData(
          state.subscriptionMetadata,
          deviceId,
          activation
        )
      }
    }
  },
  setIotCoreData(state, data) {
    if (data?.length) {
      for (const device of data) {
        const { deviceId, iotCoreData } = device
        const lastCommunicationTime =
          getLastDeviceCommunicationTimestamp(iotCoreData)
        state.lastCommunicationTime = replaceData(
          state.lastCommunicationTime,
          deviceId,
          lastCommunicationTime
        )
      }
    }
  },
  deleteRemovedDevices(state, devices) {
    const current = Object.keys(state.allDevices)
    const updated = Object.keys(devices)
    const removed = current.filter((id) => !updated.includes(id))
    for (const id of removed) {
      for (let key in state) {
        state[key][id] = undefined
      }
    }
  },
  updateBatteryCharging(state, data) {
    state.batteryCharging = replaceData(
      state.batteryCharging,
      data.deviceId,
      data.batteryCharging
    )
  },
  updateBatteryInstalled(state, data) {
    state.batteryInstalled = replaceData(
      state.batteryInstalled,
      data.deviceId,
      data.batteryInstalled
    )
  },
  updateBatteryLow(state, data) {
    state.batteryLow = replaceData(
      state.batteryLow,
      data.deviceId,
      data.batteryLow
    )
  },
  updateBatteryRemaining(state, data) {
    state.batteryRemaining = replaceData(
      state.batteryRemaining,
      data.deviceId,
      data.batteryRemaining
    )
  },
}
