<template>
  <v-hover
    v-slot:default="{ hover }"
    :open-delay="0"
    :close-delay="0"
    :value="val"
    v-if="alerts.length"
  >
    <span>
      <v-btn class="popover-status-icon" text @click="val = !val">
        <v-icon color="grey">mdi-alert</v-icon>
      </v-btn>
      <v-tooltip v-bind:value="hover" bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on"></span>
        </template>
        <span>
          <template>
            <div v-for="alert in alerts" :key="alert">- {{ alert }}</div>
          </template>
        </span>
      </v-tooltip>
    </span>
  </v-hover>
</template>

<script>
import { HTTPStatus } from '../../../api'
import { aqiValidator } from '../../../services/map'
import { getPermissionsByDeviceId } from '../../../services/subscriptions'
import { SlugsEnum } from '../../../permissions/SlugsEnum'

export default {
  props: {
    deviceId: String,
  },
  mixins: [aqiValidator],
  data() {
    return {
      val: false,
      alerts: [],
    }
  },
  async mounted() {
    this.alerts = await this.getAlerts()
  },
  computed: {
    id() {
      return this.deviceId || ''
    },
    sensorReadings() {
      return this.$store.getters['devices/getSensorReadingsByDeviceId'](this.id)
    },
    deviceModel() {
      return this.$store.getters['devices/getModelByDeviceId'](this.id)
    },
    permissions() {
      return getPermissionsByDeviceId(this.id)
    },
    hasConsecutiveZerosPermission() {
      return this.permissions.includes(SlugsEnum.ReadConsecutiveZerosWarning)
    },
  },
  watch: {
    sensorReadings: {
      async handler() {
        this.alerts = await this.getAlerts()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getAlerts() {
      let alerts = []
      this.checkErrors(alerts)

      return alerts
    },
    checkErrors(alerts) {
      const deviceErrors = this.$store.getters[
        'devices/getDeviceStatusByDeviceId'
      ](this.id)
      const sensorErrors = this.$store.getters[
        'devices/getSensorStatusByDeviceId'
      ](this.id)
      deviceErrors.forEach((err) => {
        alerts.push(err)
      })
      sensorErrors.forEach((err) => {
        alerts.push(err)
      })
      return alerts
    },
    checkFields(binStr) {
      while (binStr.length < 8) {
        binStr = '0' + binStr
      }
      const binArr = binStr.split('').reverse()
      const errArr = []

      for (let i = 0; i < binArr.length; i++) {
        if (parseInt(binArr[i])) {
          errArr.push({ code: i })
        }
      }

      return errArr
    },
  },
}
</script>
