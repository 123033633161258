<template>
  <v-tooltip bottom :disabled="!tooltip">
    <template v-slot:activator="{ on, attrs }">
      <div
        :class="{
          notSupported: !isSupported,
          measurement: !largeMeasurement,
          'measurement-large': largeMeasurement,
        }"
        v-bind="attrs"
        v-on="on"
      >
        <div class="label">
          <img
            :src="imageSrc"
            class="measurement-icon"
            alt="Measurement Icon"
          />
          <span>{{ label }}</span>
        </div>
        <div class="reading">
          {{ value }}
          <span></span>
          <span v-show="value !== $t('map.popover.valueBlank')">
            <span class="uom">{{ unitsLabel }}</span>
          </span>
        </div>
      </div>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import { activeLastDay } from '../../../services/device-status'
import { roundMeasurementToTenths } from '../../../services/unit-conversion'

export default {
  props: {
    isSupported: { type: Boolean, default: true },
    reading: Object,
    label: String,
    imageSrc: String,
    tooltip: String,
    unitsLabel: String,
    largeMeasurement: { type: Boolean, default: false },
  },
  computed: {
    readingIsValid() {
      return this.reading && this.reading !== null
    },
    value() {
      let result = this.$t('map.popover.valueBlank')
      if (this.readingIsValid && this.readingActive) {
        if (this.reading.precision) {
          result = this.reading.value.toFixed(this.reading.precision)
        } else {
          result = roundMeasurementToTenths(this.reading.value)
        }
      }
      return result
    },
    readingActive() {
      return !!(
        this.readingIsValid &&
        this.reading.timestamp &&
        activeLastDay(this.reading.timestamp)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.measurement {
  width: 122px;
  max-width: 122px;
  margin-top: 8px;

  &.notSupported {
    opacity: 0.36;
  }

  .label {
    font-size: 13px;
    display: flex;
    img {
      width: 17px;
      height: 17px;
      margin-right: 4px;
    }
  }
  .reading {
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    span.uom {
      font-size: 14px;
    }
  }
}

@media (max-width: 900px) {
  .measurement {
    width: 116px;
    max-width: 116px;
  }
}
@media (max-width: 460px) {
  .measurement {
    width: 94px;
    max-width: 94px;
    margin-right: 4px;

    .label {
      font-size: 13px;
    }
    .reading {
      font-weight: 600;
      font-size: 15px;
      span.uom {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 400px) {
  .measurement {
    width: 88px;
    max-width: 88px;
    .reading {
      font-size: 12px;
      span.uom {
        font-size: 10px;
      }
    }
  }
}
.widget-xxs,
.widget-xs,
.widget-sm {
  .measurement {
    .label {
      font-size: 13px;
    }
    .reading {
      font-weight: 600;
      font-size: 15px;
      span.uom {
        font-size: 12px;
      }
    }
  }
}
.widget-xxs {
  .measurement {
    margin-top: 0;
    height: 50px;
    width: 31%;
    max-width: unset;
    .label {
      font-size: 12px;
    }
    .reading {
      font-weight: 600;
      font-size: 14px;
      span.uom {
        font-size: 10px;
      }
    }
  }
}

.widget-xs,
.widget-sm {
  .measurement {
    width: 31%;
    height: 42px;
    max-width: unset;
  }
}

.widget-md {
  .measurement {
    width: 31%;
    height: 44px;
    max-width: unset;
  }
}

.widget-lg {
  .measurement {
    width: 33%;
    height: 44px;
    max-width: unset;
    .label {
      font-size: 16px;
      img {
        width: 17px;
        height: 17px;
        margin-right: 4px;
      }
    }
    .reading {
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      span.uom {
        font-size: 14px;
      }
    }
  }
}

.widget-xl {
  .measurement {
    width: 25%;
    height: 64px;
    max-width: unset;
    .label {
      font-size: 16px;
      img {
        width: 17px;
        height: 17px;
        margin-right: 4px;
      }
    }
    .reading {
      font-weight: 600;
      font-size: 20px;
      line-height: 18px;
      span.uom {
        font-size: 16px;
      }
    }
  }
}

// LARGE MEASUREMENTS
.measurement-large {
  display: flex;
  margin-top: 26px;
  margin-left: 16px;
  margin-bottom: 16px;

  &.notSupported {
    opacity: 0.36;
  }

  .label {
    font-size: 20px;
    display: flex;
    margin-right: 16px;
    width: 100px;
    img {
      width: 22px;
      height: 22px;
      margin-right: 4px;
    }
  }
  .reading {
    font-weight: 600;
    font-size: 24px;
    line-height: 18px;
    span.uom {
      font-size: 16px;
    }
  }
}

.widget-xxs {
  .measurement-large {
    margin-top: 35px;
    margin-left: 8px;
    margin-bottom: 0;
  }
}
.widget-xs {
  .measurement-large {
    margin-top: 36px;
    margin-left: 16px;
    margin-bottom: 0;
  }
}
.widget-sm {
  .measurement-large {
    margin-top: 40px;
    margin-left: 16px;
    margin-bottom: 16px;
  }
}
.widget-md,
.widget-lg,
.widget-xl {
  .measurement-large {
    margin-top: 42px;
    margin-left: 16px;
    margin-bottom: 16px;
  }
}
</style>
