<template>
  <v-row
    class="measurement-rows"
    :class="{ 'full-rows': hasFullRows }"
    no-gutters
  >
    <popover-measurement
      :reading="readingPm"
      :label="$t(`readings.${pmReadingType}`)"
      :unitsLabel="pmReadingUnitLabel"
      imageSrc="/img/measurements/pm.svg"
    />
    <popover-measurement
      :reading="readingTemp"
      :label="$t(`readings.temp`)"
      :unitsLabel="$t(`units.${tempReadingType}`)"
      imageSrc="/img/measurements/temp.svg"
    />
    <popover-measurement
      v-if="isMeasurementVisible(types.rh)"
      :isSupported="isMeasurementSupported(types.rh)"
      :reading="readingHumidity"
      :label="$t(`readings.rh`)"
      :unitsLabel="$t('units.percent')"
      imageSrc="/img/measurements/rh.svg"
    />
    <popover-measurement
      v-if="isMeasurementVisible(types.baro_inhg)"
      :isSupported="isMeasurementSupported(types.baro_inhg)"
      :reading="readingBp"
      :label="$t(`readings.baro_inhg`)"
      :unitsLabel="$t(`units.${pressureReadingUnitLabel}`)"
      imageSrc="/img/measurements/bp.svg"
    />
    <popover-measurement
      v-if="isMeasurementVisible(types.co2)"
      :isSupported="isMeasurementSupported(types.co2)"
      :reading="readingCo2"
      :label="$t(`readings.co2`)"
      :unitsLabel="$t('units.ppm')"
      imageSrc="/img/measurements/co2.svg"
    />
    <popover-measurement
      v-if="isMeasurementVisible(types.tvoc)"
      :isSupported="isMeasurementSupported(types.tvoc, SlugsEnum.GasReadTVOC)"
      :reading="readingTvoc"
      :tooltip="getUpgradeTooltip(types.tvoc, SlugsEnum.GasReadTVOC)"
      :label="$t(`readings.tvoc`)"
      :unitsLabel="$t('units.mgm3')"
      imageSrc="/img/measurements/voc.svg"
    />
    <popover-measurement
      v-if="isMeasurementVisible(types.ch2o)"
      :isSupported="isMeasurementSupported(types.ch2o, SlugsEnum.GasReadCH2O)"
      :reading="readingCh2o"
      :tooltip="getUpgradeTooltip(types.ch2o, SlugsEnum.GasReadCH2O)"
      :label="$t(`readings.ch2o`)"
      :unitsLabel="$t('units.ppb')"
      imageSrc="/img/measurements/ch2o.svg"
    />
    <popover-measurement
      v-if="isMeasurementVisible(types.co)"
      :isSupported="isMeasurementSupported(types.co, SlugsEnum.GasReadCO)"
      :reading="readingCo"
      :tooltip="getUpgradeTooltip(types.co, SlugsEnum.GasReadCO)"
      :label="$t(`readings.co`)"
      :unitsLabel="$t('units.ppm')"
      imageSrc="/img/measurements/co.svg"
    />
    <popover-measurement
      v-if="isMeasurementVisible(types.so2)"
      :isSupported="isMeasurementSupported(types.so2)"
      :reading="readingSo2"
      :tooltip="getUpgradeTooltip(types.so2, SlugsEnum.GasReadSO2)"
      :label="$t(`readings.so2`)"
      :unitsLabel="$t('units.ppb')"
      imageSrc="/img/measurements/so2.svg"
    />
    <popover-measurement
      v-if="isMeasurementVisible(types.no2)"
      :isSupported="isMeasurementSupported(types.no2)"
      :reading="readingNo2"
      :tooltip="getUpgradeTooltip(types.no2, SlugsEnum.GasReadNO2)"
      :label="$t(`readings.no2`)"
      :unitsLabel="$t('units.ppb')"
      imageSrc="/img/measurements/no2.svg"
    />
    <popover-measurement
      v-if="isMeasurementVisible(types.o3)"
      :isSupported="isMeasurementSupported(types.o3)"
      :reading="readingO3"
      :label="$t(`readings.o3`)"
      :tooltip="getUpgradeTooltip(types.o3, SlugsEnum.GasReadO3)"
      :unitsLabel="$t('units.ppb')"
      imageSrc="/img/measurements/o3.svg"
    />
  </v-row>
</template>

<script>
import { SlugsEnum } from '../../../../permissions/SlugsEnum'
import popoverMeasurement from '../popoverMeasurement'
import { convertArrayToObject } from '../../../../services/data-conversion'
import { measurementTypes as measTypes } from '../../../../services/device-measurements'
import {
  getMeasurementTypes,
  getReadingPM,
  getReadingTemperature,
  getReadingPressure,
  getReadingValue,
  getTempReadingType,
} from '../../utils'
import { getUserAccountId } from '../../../../helpers/loginas/logInAsHelper'
import { UnitOfMeasure, UnitOfMeasureProperties } from '@fusion/units'

export default {
  props: {
    deviceId: String,
    permissions: Array,
    sensorReadings: Array,
  },
  data() {
    return {
      accountId: null,
      hasFullRows: false,
    }
  },
  components: {
    'popover-measurement': popoverMeasurement,
  },
  computed: {
    SlugsEnum: function () {
      return SlugsEnum
    },
    deviceType: function () {
      return this.$store.getters['devices/getDeviceTypeByDeviceId'](this.deviceId)
    },
    types: function () {
      return measTypes
    },
    readings: function () {
      return convertArrayToObject(this.sensorReadings, 'name')
    },
    pmReadingType: function () {
      const measType = this.$store.getters['map/getMeasurementType']
      return getMeasurementTypes(measType)
    },
    pmReadingUnit: function () {
      return this.$store.state.userProfile?.settings.pmUnit || UnitOfMeasure.MICROGRAMS_PER_CUBIC_METER
    },
    pmReadingUnitLabel: function () {
      return UnitOfMeasureProperties[this.pmReadingUnit]?.code || ''
    },
    pressureReadingUnitLabel: function () {
      return UnitOfMeasureProperties[this.pressureReadingUnit]?.code || ''
    },
    pressureReadingUnit: function () {
      return this.$store.state.userProfile?.settings.pressureUnit || UnitOfMeasure.INCH_MERCURY
    },
    tempReadingType: function () {
      const tempUnit = this.$store.state.userProfile?.settings.tempUnit
      return getTempReadingType(tempUnit)
    },
    readingPm: function () {
      return getReadingPM(
        this.readings,
        this.pmReadingUnit,
        this.deviceType,
        this.pmReadingType
      )
    },
    readingTemp: function () {
      return getReadingTemperature(this.readings, this.tempReadingType)
    },
    readingHumidity: function () {
      return getReadingValue(
        this.readings,
        'Relative Humidity',
        this.isMeasurementSupported(measTypes.rh)
      )
    },
    readingBp: function () {
      return getReadingPressure(
        this.readings,
        this.pressureReadingUnit,
        this.deviceType,
        this.isMeasurementAllowedAndSupported(measTypes.baro_inhg)
      )
    },
    readingCo2: function () {
      return getReadingValue(
        this.readings,
        'co2',
        this.isMeasurementAllowedAndSupported(measTypes.co2)
      )
    },
    readingCh2o: function () {
      const isAllowedAndSupported = this.isMeasurementAllowedAndSupported(
        measTypes.ch2o,
        SlugsEnum.GasReadCH2O
      )
      return getReadingValue(this.readings, 'ch2o', isAllowedAndSupported)
    },
    readingTvoc: function () {
      const isAllowedAndSupported = this.isMeasurementAllowedAndSupported(
        measTypes.tvoc,
        SlugsEnum.GasReadTVOC
      )
      return getReadingValue(this.readings, 'tvoc', isAllowedAndSupported)
    },
    readingCo: function () {
      const isAllowedAndSupported = this.isMeasurementAllowedAndSupported(
        measTypes.co,
        SlugsEnum.GasReadCO
      )
      return getReadingValue(this.readings, 'co', isAllowedAndSupported)
    },
    readingSo2: function () {
      const isAllowedAndSupported = this.isMeasurementAllowedAndSupported(
        measTypes.so2,
        SlugsEnum.GasReadSO2
      )
      return getReadingValue(this.readings, 'so2', isAllowedAndSupported)
    },
    readingO3: function () {
      const isAllowedAndSupported = this.isMeasurementAllowedAndSupported(
        measTypes.o3,
        SlugsEnum.GasReadO3
      )
      return getReadingValue(this.readings, 'o3', isAllowedAndSupported)
    },
    readingNo2: function () {
      const isAllowedAndSupported = this.isMeasurementAllowedAndSupported(
        measTypes.no2,
        SlugsEnum.GasReadNO2
      )
      return getReadingValue(this.readings, 'no2', isAllowedAndSupported)
    },
    model() {
      return this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
    },
    submodel() {
      return this.$store.getters['devices/getSubmodelByDeviceId'](this.deviceId)
    },
    isMine() {
      return (
        this.accountId ===
        this.$store.getters['devices/getAccountByDeviceId'](this.deviceId)
      )
    },
    isShared() {
      return this.$store.getters['devices/getIsSharedByDeviceId'](this.deviceId)
    },
    supportedMeasurements() {
      const isPublicOnly = !this.device.isMine && !this.device.isShared
      return this.$store.getters['devicemodels/getSupportedMeasurements'](
        this.device.model,
        this.device.submodel,
        isPublicOnly
      )
    },
    visibleMeasurements() {
      return this.$store.getters['devicemodels/getSupportedMeasurements'](
        this.device.model,
        this.device.submodel
      )
    },
    device() {
      return {
        model: this.model,
        submodel: this.submodel,
        isMine: this.isMine,
        isShared: this.isShared,
      }
    },
  },
  watch: {
    async deviceId() {
      await this.setFullRows()
    },
  },
  methods: {
    getUpgradeTooltip(measurementType, slug) {
      if (
        !this.isMeasurementAllowed(slug) &&
        this.isMeasurementSupported(measurementType) &&
        this.isMine
      ) {
        return this.$t('accountSubscriptions.upgradeData')
      }
      return undefined
    },
    isMeasurementAllowed(slug) {
      let isAllowed = false
      if (slug) {
        isAllowed = this.permissions.includes(slug)
      }
      return isAllowed
    },
    isMeasurementVisible(measurementType) {
      return this.visibleMeasurements.includes(measurementType)
    },
    isMeasurementSupported(measurementType) {
      return this.supportedMeasurements.includes(measurementType)
    },
    isMeasurementAllowedAndSupported(measurementType, slug) {
      const isSupported = this.isMeasurementSupported(measurementType)
      if (isSupported && slug) {
        return this.isMeasurementAllowed(slug)
      }
      return isSupported
    },
    async setAccountId() {
      this.accountId = await getUserAccountId(this.$auth)
    },
    async setFullRows() {
      await this.$nextTick()
      this.hasFullRows = this.$children.length % 3 === 0
    },
  },
  async mounted() {
    await this.setAccountId()
    await this.setFullRows()
  },
}
</script>

<style lang="scss" scoped>
.measurement-rows {
  padding-bottom: 12px;

  &.full-rows {
    padding-bottom: 56px;

    [class*='widget'] & {
      padding-bottom: 12px;
    }
  }
}
</style>
