import { LOG_IN_AS_HEADER_NAMES } from './utils'
import { getLogInAsHeaders } from './helper'

export const getLatestStudyRegistrations = async function () {
  return this.GET(
    '/studies/registration/serials/latest?ignoredGatewayModels=sbd1,SBD1,SmartBridge',
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
      ]),
    },
    {},
    process.env.VUE_APP_STUDIES_HOST_V2
  )
}

export const getFileImportCount = async function () {
  return this.GET(
    '/studies/registration/file-import',
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
        LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
      ]),
    },
    {},
    process.env.VUE_APP_STUDIES_HOST_V2
  )
}

export const getTotalStorageUsed = async function () {
  return this.GET(
    '/studies/storage',
    {
      ...getLogInAsHeaders([
        LOG_IN_AS_HEADER_NAMES.SAP_ID,
        LOG_IN_AS_HEADER_NAMES.ACCOUNT_ID,
      ]),
    },
    {},
    process.env.VUE_APP_STUDIES_HOST_V2
  )
}
