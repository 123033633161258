<template>
  <div class="averages">
    <v-row>
      <div class="label">{{ $t(`map.popover.average`) }}</div>
    </v-row>
    <v-row>
      <popover-average
        data-cy="pocHalfHour"
        :value="averages.halfHour"
        name="halfHour"
        class="hide-xxs"
      />
      <popover-average
        data-cy="pocOneHour"
        :value="averages.oneHour"
        name="oneHour"
      />
      <popover-average
        data-cy="pocSixHour"
        :value="averages.sixHour"
        name="sixHour"
        class="hide-xs"
      />
      <popover-average
        data-cy="pocTwentyFourHour"
        :value="averages.oneDay"
        name="oneDay"
      />
      <popover-average
        data-cy="pocOneWeek"
        :value="averages.oneWeek"
        name="oneWeek"
      />
    </v-row>
  </div>
</template>

<script>
import popoverAverage from './popoverAverage.vue'
import { aqiValidator } from '../../../services/map'
import { measurementTypes } from '../../../store/map/utils'
import { DEVICE_UPDATE_INTERVAL_MS } from '../../../services/device-metadata'

export default {
  props: {
    deviceId: String,
  },
  components: {
    'popover-average': popoverAverage,
  },
  mixins: [aqiValidator],
  data() {
    return {
      loadDataInterval: null,
    }
  },
  computed: {
    id: function () {
      return this.deviceId || ''
    },
    averages: function () {
      let a = {
        halfHour: null,
        oneHour: null,
        sixHour: null,
        twelveHour: null,
        oneDay: null,
        oneWeek: null,
      }
      const measType = this.$store.getters['map/getMeasurementType']
      const avgs = this.$store.getters['devices/getAveragesByDeviceId'](this.id)
      if (avgs) {
        if (measType === measurementTypes.pm25) {
          a = this.getAverages('2x5', avgs)
        }
        if (measType === measurementTypes.pm10) {
          a = this.getAverages('10', avgs)
        }
      }
      return a
    },
  },
  methods: {
    getAverages: function (aqiType, avgs) {
      return {
        halfHour: this.validateAQI(avgs[`pm${aqiType}_aqi_30m`]),
        oneHour: this.validateAQI(avgs[`pm${aqiType}_aqi_1h`]),
        sixHour: this.validateAQI(avgs[`pm${aqiType}_aqi_6h`]),
        twelveHour: this.validateAQI(avgs[`pm${aqiType}_aqi_12h`]),
        oneDay: this.validateAQI(avgs[`pm${aqiType}_aqi_1d`]),
        oneWeek: this.validateAQI(avgs[`pm${aqiType}_aqi_1w`]),
      }
    },
    startLoadDataInterval: function () {
      this.loadData()
      if (this.loadDataInterval === null) {
        this.loadDataInterval = setInterval(
          this.loadData,
          DEVICE_UPDATE_INTERVAL_MS
        )
      }
    },
    stopLoadDataInterval: function () {
      if (this.loadDataInterval !== null) {
        clearInterval(this.loadDataInterval)
        this.loadDataInterval = null
      }
    },
    loadData: function () {
      if (this.deviceId) {
        this.$store.dispatch(
          'devices/getTelemetryAveragesAndUpdate',
          this.deviceId
        )
      }
    },
  },
  mounted() {
    this.startLoadDataInterval()
  },
  destroyed() {
    this.stopLoadDataInterval()
  },
}
</script>

<style lang="scss" scoped>
.averages {
  padding: 12px 16px 0 0;

  .label {
    margin-bottom: 0.2rem;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 300;
  }

  .widget-xxs &,
  .widget-xs &,
  .widget-sm & {
    padding: 12px 12px 0 0;
    .row {
      flex-wrap: nowrap;
    }
  }

  .widget-xs &,
  .widget-xxs & {
    .hide-xs {
      display: none;
    }
  }
  .widget-xxs & {
    .hide-xxs {
      display: none;
    }
  }
}
</style>
