<template>
  <v-card-subtitle data-cy="lastDeviceMeasurementText">
    <span :class="{ inactiveTimestamp: !activeLastHour }">
      <span class="lastMeasurementShort">
        {{ $t('map.popover.timestamp.lastMeasurementShort') }}:
      </span>
      <span class="lastMeasurement">
        {{ $t('map.popover.timestamp.lastMeasurement') }}:
      </span>
      {{
        timestamp != $t('map.popover.timestamp.lastMeasurementNever')
          ? new Date(timestamp).toLocaleString()
          : timestamp
      }}
    </span>
  </v-card-subtitle>
</template>

<script>
import {
  activeLastHour,
  validateTimestamp,
} from '../../../services/device-status'

export default {
  props: {
    deviceId: String,
  },
  computed: {
    lastContact() {
      return this.$store.getters['devices/getLastContactByDeviceId'](
        this.deviceId
      )
    },
    activeLastHour() {
      return activeLastHour(this.lastContact)
    },
    sensorReadings() {
      return this.$store.getters['devices/getSensorReadingsByDeviceId'](
        this.deviceId
      )
    },
    timestamp() {
      const noTS = this.$t('map.popover.timestamp.lastMeasurementNever')
      if (
        !Array.isArray(this.sensorReadings) ||
        this.sensorReadings.length === 0
      ) {
        return noTS
      } else {
        return validateTimestamp(this.sensorReadings[0].timestamp, noTS)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card__subtitle {
  padding: 0.4rem 0.5rem;
  white-space: nowrap;
  span {
    font-size: 12px;
  }
  .inactiveTimestamp {
    color: #fa1717;
  }
}
.lastMeasurementShort {
  display: none;
}
.lastMeasurement {
  display: unset;
}
.widget-sm,
.widget-xs,
.widget-xxs {
  .measurement {
    width: 96px;
    max-width: 96px;
  }
}
.widget-xs,
.widget-xxs {
  .lastMeasurementShort {
    display: unset;
  }
  .lastMeasurement {
    display: none;
  }
}
</style>
