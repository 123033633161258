<template>
  <div class="average">
    <div class="label">{{ $t(`map.popover.averages.${name}`) }}</div>
    <div v-if="value === undefined || value == null" class="reading">
      {{ $t('map.popover.valueBlank') }}
    </div>
    <div v-else class="reading">{{ value }}</div>
    <div class="color" :style="aqiStyles(value)"></div>
  </div>
</template>

<script>
import { getAQIStyles } from '../../../services/map'

export default {
  props: {
    value: [Number, String],
    name: String,
  },
  methods: {
    aqiStyles: function (aqi) {
      const styles = getAQIStyles(aqi)

      if (styles.background === '#eeeeee') {
        return `color:${styles.color};transition:background-color 0s ease-in;`
      }
      return `background-color:${styles.background};color:${styles.color}`
    },
  },
}
</script>

<style lang="scss" scoped>
.average {
  margin-right: 8px;
  width: 44px;
  .label {
    margin-bottom: 0.2rem;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 300;
  }
  .reading {
    font-weight: 500;
    margin-top: 2px;
    color: rgba(0, 0, 0, 0.66);
    font-size: 13px;
  }
  .color {
    margin-top: 2px;
    width: 100%;
    height: 4px;
    background: #eeeeee;
    border-radius: 2px;
    transition: background-color 500ms ease-in;
  }
}

@media (max-width: 460px) {
  .average {
    margin-right: 4px;
    width: 42px;
    .label {
      font-size: 12px;
      white-space: nowrap;
    }
  }
}
@media (max-width: 400px) {
  .average {
    margin-right: 4px;
    width: 37px;
    .label {
      font-size: 11px;
      white-space: nowrap;
    }
  }
}
.widget-sm {
  .average {
    margin-right: 4px;
    width: 37px;
    .label {
      font-size: 12px;
      white-space: nowrap;

      @media (max-width: 460px) {
        white-space: nowrap;
      }
    }
  }
}
.widget-xs {
  .average {
    margin-right: 4px;
    width: 44px;
    .label {
      font-size: 12px;
      white-space: nowrap;

      @media (max-width: 460px) {
        white-space: nowrap;
      }
    }
  }
}

.widget-xxs {
  .average {
    margin-right: 4px;
    width: 42px;
    .label {
      font-size: 12px;
      white-space: nowrap;

      @media (max-width: 460px) {
        white-space: nowrap;
      }
    }
  }
}
</style>
