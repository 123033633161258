<template>
  <div>
    <v-menu offset-y v-if="canAccess(access.FULL, accessLevel)">
      <template v-slot:activator="{ on }">
        <v-btn
          class="menu-dropdown black--text"
          text
          v-on="on"
          data-cy="devicePopoverDropdown"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <!-- Device Info -->
        <v-list-item
          @click.stop="openDeviceInformationDialog"
          link
          data-cy="deviceInfoActivator"
        >
          <v-icon>mdi-information</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceInfo') }}
          </v-list-item-title>
        </v-list-item>
        <!-- Device Settings -->
        <v-list-item
          @click.stop="openDeviceSettingsDialog"
          link
          data-cy="deviceSettingsActivator"
        >
          <v-icon>mdi-settings-box</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceSettings') }}
          </v-list-item-title>
        </v-list-item>
        <!-- Device Alerts -->
        <v-list-item
          @click.stop="openDeviceAlertsDialog"
          link
          data-cy="deviceAlertsActivator"
        >
          <v-icon>mdi-bell</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceAlerts') }}
          </v-list-item-title>
        </v-list-item>
        <!-- Move Pin -->
        <v-list-item
          @click="startMovePin"
          data-cy="movePin"
        >
          <v-icon>mdi-cursor-move</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.movePin') }}
          </v-list-item-title>
        </v-list-item>
        <!-- Device Sharing -->
        <v-list-item
          @click.stop="openDeviceSharingDialog"
          link
          data-cy="deviceSharing"
        >
          <v-icon>mdi-share-variant</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceSharing') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <device-info-dialog
      :open="dialogs.info"
      :deviceId="deviceId"
      @close="closeDeviceInformationDialog"
    />
    <device-settings-dialog
      :open="dialogs.settings"
      :deviceId="deviceId"
      :permissions="permissions"
      @close="closeDeviceSettingsDialog"
      @unregister="handleUnregister"
    />
    <device-alerts-dialog
      :open="dialogs.alerts"
      :deviceId="deviceId"
      :permissions="permissions"
      @close="closeDeviceAlertsDialog"
    />
    <device-sharing-dialog
      :open="dialogs.sharing"
      :deviceId="deviceId"
      :permissions="permissions"
      @close="closeDeviceSharingDialog"
    />
  </div>
</template>

<script>
import { accessUtil } from '../../../services/device-access'
import DeviceInfoDialog from '../../dialogs/deviceInfo/DeviceInfoDialog.vue'
import DeviceSettingsDialog from '../../dialogs/DeviceSettingsDialog.vue'
import DeviceSharingDialog from '../../dialogs/DeviceSharingDialog.vue'
import DeviceAlertsDialog from '../../dialogs/DeviceAlertsDialog.vue'

export default {
  name: 'PopoverMenu',
  props: {
    deviceId: String,
    accessLevel: Number,
    permissions: Array,
  },
  mixins: [accessUtil],
  components: {
    'device-info-dialog': DeviceInfoDialog,
    'device-settings-dialog': DeviceSettingsDialog,
    'device-sharing-dialog': DeviceSharingDialog,
    'device-alerts-dialog': DeviceAlertsDialog,
  },
  data() {
    return {
      dialogs: {
        info: false,
        settings: false,
        alerts: false,
        sharing: false,
      },
    }
  },
  methods: {
    openDeviceInformationDialog: function () {
      this.dialogs.info = true
    },
    closeDeviceInformationDialog: function () {
      this.dialogs.info = false
    },
    openDeviceSettingsDialog: function () {
      this.dialogs.settings = true
    },
    closeDeviceSettingsDialog: function () {
      this.dialogs.settings = false
    },
    openDeviceAlertsDialog: function () {
      this.dialogs.alerts = true
    },
    closeDeviceAlertsDialog: function () {
      this.dialogs.alerts = false
    },
    openDeviceSharingDialog: function () {
      this.dialogs.sharing = true
    },
    closeDeviceSharingDialog: function () {
      this.dialogs.sharing = false
    },
    startMovePin: function () {
      const device = {
        deviceId: this.deviceId,
        coords: this.$store.getters['devices/getCoordsByDeviceId'](
          this.deviceId
        ),
      }
      this.$store.dispatch('map/startMoving', device)
    },
    handleUnregister: function () {
      this.$emit('unregister')
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-dropdown {
  min-width: 10px !important;
  padding: 0 0.4rem !important;
}
</style>
