<template>
  <v-card-title>
    <slot name="menu"></slot>
    <div class="titleContainer">
      <v-tooltip
        v-if="canAccess(access.SHARED, accessLevel)"
        bottom
        :max-width="600"
        :disabled="!isChildOverflowing($refs.deviceName)"
      >
        <template v-slot:activator="{ on }">
          <div
            v-on="on"
            class="device-name"
            :class="{
              'device-name__public': isAccess(access.SHARED, accessLevel),
              mobile: $vuetify.breakpoint.xs,
            }"
            ref="deviceName"
          >
            <span>{{ name }}</span>
          </div>
        </template>
        <span>{{ name }}</span>
      </v-tooltip>
      <div v-else class="device-name__public" data-cy="devicePopoverName">
        {{ $t('map.popover.publicDevice') }}
      </div>
      <div
        class="device-type"
        :class="{
          'device-type__public':
            isAccess(access.NONE, accessLevel) ||
            isAccess(access.SHARED, accessLevel),
        }"
      >
        {{ deviceType }}
      </div>
      <div v-if="serial" class="device-serial" :class="{'device-serial__shared': isAccess(access.SHARED, accessLevel)}"
        data-cy="devicePopoverSerial">
        {{serial }}
      </div>
    </div>
    <v-spacer></v-spacer>
    <div v-if="canAccess(access.FULL, accessLevel)">
      <slot name="status"></slot>
    </div>
  </v-card-title>
</template>

<script>
import { accessUtil } from '../../../services/device-access'
import { isChildOverflowing } from '../../../services/html-utilities'

export default {
  name: 'PopoverTitle',
  props: {
    accessLevel: Number,
    deviceType: String,
    name: String,
    serial: String,
  },
  data() {
    return {
      isChildOverflowing,
    }
  },
  mixins: [accessUtil],
}
</script>

<style lang="scss" scoped>
.v-card__title {
  flex-wrap: nowrap;
  min-height: 42px;
  padding: 0 4px;
  font-size: 15px;

  .titleContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 4px;
    overflow: hidden;

    .device-name {
      font-size: 16px;
      margin-right: 4px;
      margin-left: 4px;
      height: 22px;
      line-height: 26px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &__public {
        margin-right: 4px;
        margin-left: 12px;
        height: 22px;
        line-height: 26px;
      }

      &.mobile {
        font-size: 15px;
      }
    }
    .device-type,
    .device-serial {
      margin-left: 4px;
      height: 12px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);

      &__public,
      &__shared {
        margin-left: 12px;
      }
    }
  }
}
</style>
