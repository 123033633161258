var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"open":_vm.open,"title":_vm.dialogTitle,"data-cy":"addWidgetsDialog","fullscreen":_vm.$vuetify.breakpoint.xs,"persistentOverlay":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,on)]}},{key:"header",fn:function(){return [_c('v-card-title',[_c('v-icon',{staticClass:"header-icon append-plus"},[_vm._v("mdi-view-dashboard")]),_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('dashboard.addWidgetsDialog.subtitle'))+" ")])]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"black--text",attrs:{"text":"","outlined":"","data-cy":"addWidgetsCancelButton"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('buttons.cancel')))]),_c('v-spacer'),_c('v-btn',{staticClass:"primary white--text",attrs:{"disabled":_vm.saveDisabled,"text":"","data-cy":"addWidgetsDoneButton"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('buttons.done')))])]},proxy:true}],null,true)},[_c('v-card-text',[(!_vm.editing)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.widgetOptions,"rules":[_vm.widgetsRemainingValid],"label":_vm.$t('dashboard.addWidgetsDialog.widgetType'),"data-cy":"widgetTypeDropdown"},on:{"change":function($event){return _vm.getWidgetsRemaining()}},model:{value:(_vm.selectedWidget),callback:function ($$v) {_vm.selectedWidget=$$v},expression:"selectedWidget"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.allowMultipleDevices)?_c('v-autocomplete',{attrs:{"multiple":"","clearable":"","items":_vm.selectOptions,"label":_vm.$t('dashboard.addWidgetsDialog.device.label'),"no-data-text":_vm.$t('dashboard.addWidgetsDialog.device.noDataText'),"hint":_vm.$t('dashboard.addWidgetsDialog.device.hintMultipleDevices', {
              qty: _vm.maxDeviceSelect,
            }),"persistent-hint":"","disabled":_vm.disabledDeviceSelect,"data-cy":"widgetDeviceDropdown"},on:{"focus":_vm.setDropdownWidth},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(_vm.selectedDeviceIds.length === 1)?_c('div',{staticClass:"device-selections"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.selectedDeviceIds.length > 1 && index === 0)?_c('div',{staticClass:"device-selections"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.addWidgetsDialog.device.qtyDevicesSelected', { qty: _vm.selectedDeviceIds.length, }))+" ")]):_vm._e()]}},{key:"item",fn:function(ref){
            var item = ref.item;
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":_vm.disableDeviceSelectItem(item)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"ripple":false,"input-value":active,"disabled":_vm.disableDeviceSelectItem(item)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"select-item__title"},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.subtitle)+" ")])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,3785491017),model:{value:(_vm.selectedDeviceId),callback:function ($$v) {_vm.selectedDeviceId=$$v},expression:"selectedDeviceId"}}):_c('v-autocomplete',{attrs:{"items":_vm.selectOptions,"label":_vm.$t('dashboard.addWidgetsDialog.device.label'),"no-data-text":_vm.$t('dashboard.addWidgetsDialog.device.noDataText'),"disabled":_vm.disabledDeviceSelect,"data-cy":"widgetDeviceDropdown"},on:{"focus":_vm.setDropdownWidth},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"select-item__title"},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.subtitle)+" ")])],1)],1)]}}]),model:{value:(_vm.selectedDeviceId),callback:function ($$v) {_vm.selectedDeviceId=$$v},expression:"selectedDeviceId"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }