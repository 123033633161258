export default {
  // All state members here are objects where first level keys are the device id
  accountId: {},
  coords: {},
  model: {},
  submodel: {},
  serial: {},
  indoor: {},
  name: {},
  locationName: {},
  public: {},
  shared: {},
  privacyZone: {},
  sensorReadings: {},
  deviceStatus: {},
  sensorStatus: {},
  rssi: {},
  connectionType: {},
  averages: {},
  percentChanged: {},
  lastCommunicationTime: {},
  lastTelemetry: {},
  lastState: {},
  firmware: {},
  sensorEoL: {},
  userCals: {},
  zeroAppliedAt: {},
  zeroState: {},
  measurements: {},
  allDevices: {},
  allUserDevices: {},
  subscriptionMetadata: {},
  subscriptionType: {},
  connectedSmartBridgeModel: {},
  connectedSmartBridgeSerial: {},
  isConnected: {},
  telemTxType: {},
  uploadStatus: {},
  batteryCharging:{},
  batteryInstalled:{},
  batteryLow:{},
  batteryRemaining:{},
}
