import { UnitOfMeasure } from '@fusion/units'
import {
  convertCelsiusToFahrenheit,
  convertPMReading,
  convertPressureReading,
  tempUnits,
} from '../../services/unit-conversion'
import { measurementTypes } from '../../store/map/utils'

export function getReadings(sensorReadings, tempUnit, noVal, noTS) {
  const r = {}
  pullValues(r, sensorReadings, noVal, noTS)

  r.tempUnit = tempUnit
  r.temp = r.tempF
  if (r.tempUnit == tempUnits.celsius) {
    r.temp = r.tempC
  }

  return r
}

function pullValues(r, readings, noVal, noTS) {
  if (readings?.length) {
    readings.forEach((measurement) => {
      r[measurement.name] =
        measurement.value || measurement.value === 0 ? measurement.value : noVal
    })
    // TODO What a mess the temp is right now - it's in temp, Temperature tempC and tempF Maybe should clean that up
    if (r.Temperature) {
      r.tempC = r.Temperature
      r.tempF = convertCelsiusToFahrenheit(r.Temperature)
    }
    r.timestamp = readings[0].timestamp || noTS
  }
}

export function getMeasurementByType(measurements, type, valueIfNotFound) {
  // why is type always sps30_mcpm2x5_aqi this is never what is in the measurements SAD
  if (measurements?.length > 0) {
    const measurement = measurements.filter((m) => m.name === type)[0]
    //can we assume one with each type? type doesn't even seem to be the right thing to compage to, most don't match
    return measurement ? measurement.value : valueIfNotFound
  }
  return valueIfNotFound
}

export function getMeasurementTypes(measType) {
  let pmType = ''
  switch (measType) {
    case measurementTypes.pm25:
      pmType = 'mcpm2x5'
      break
    case measurementTypes.pm10:
      pmType = 'mcpm10'
      break
  }
  return pmType
}

export function getTempReadingType(tempUnit) {
  switch (tempUnit) {
    case tempUnits.fahrenheit:
    case UnitOfMeasure.DEGREES_F:
      return 'fahrenheit'
    case tempUnits.kelvin:
    case UnitOfMeasure.DEGREES_K:
      return 'kelvin'
    case tempUnits.celsius:
    case UnitOfMeasure.DEGREES_C:
    default:
        return 'celsius'
  }
}

export function getReadingPM(readings, userSetting, deviceType, pmReadingType) {
  let baseReading
  if (readings !== null && pmReadingType === 'mcpm2x5') {
    baseReading = readings['pm2.5']
  } else if (readings && pmReadingType === 'mcpm10') {
    baseReading = readings['pm10']
  }
  if (baseReading) {
    const newValue = convertPMReading(baseReading, userSetting, deviceType, pmReadingType)
    if (newValue) {
      return {
        ...baseReading,
        value: newValue
      }
    }
  }
  return null
}

export function getReadingTemperature(readings, tempReadingType) {
  if (readings !== null && tempReadingType === 'celsius') {
    return readings['Temperature']
  } else if (readings && tempReadingType === 'fahrenheit') {
    const convertedTempReading = { ...readings['Temperature'] }
    convertedTempReading.value = convertCelsiusToFahrenheit(
      convertedTempReading.value
    )
    return convertedTempReading
  }
  return null
}

export function getReadingPressure(readings, pressureReadingType, deviceType, isAllowedAndSupported = true) {
  let newValue = null
  if (readings !== null && isAllowedAndSupported) {
    newValue = convertPressureReading(readings['pressure'], pressureReadingType, deviceType)
  }
  if (newValue) {
    const convertedPressureReading = { ...readings['pressure'] }
    convertedPressureReading.value = newValue
    return convertedPressureReading
  }
  return null
}

export function getReadingValue(
  readings,
  measurement,
  isAllowedAndSupported = true
) {
  if (
    readings !== null &&
    Object.hasOwn(readings, measurement) &&
    isAllowedAndSupported
  ) {
    return readings[measurement]
  }
  return null
}
