<template>
  <div>
    <popover-timestamp :deviceId="deviceId" />
    <v-row no-gutters>
      <popover-main-value :aqi="currentReading.aqi" />
      <v-spacer />
      <popover-averages :deviceId="deviceId" ref="averages" />
    </v-row>
    <popover-percent-changed :deviceId="deviceId" ref="percentChanged" />
    <popover-aqi-description :aqi="currentReading.aqi" />
    <popover-measurements
      :deviceId="id"
      :permissions="permissions"
      :sensorReadings="sensorReadings"
    />
  </div>
</template>

<script>
// Content components
import popoverTimestamp from './popoverTimestamp.vue'
import popoverMainValue from './popoverMainValue.vue'
import popoverAverages from './popoverAverages.vue'
import popoverPercentChanged from './popoverPercentChanged.vue'
import popoverAQIDescription from './popoverAQIDescription.vue'
import popoverMeasurements from './popoverMeasurements.vue'

import { getMeasurementByType } from '../utils'
import { measurementTypes } from '../../../store/map/utils'
import { activeLastDay } from '../../../services/device-status'

export default {
  components: {
    'popover-timestamp': popoverTimestamp,
    'popover-main-value': popoverMainValue,
    'popover-averages': popoverAverages,
    'popover-percent-changed': popoverPercentChanged,
    'popover-aqi-description': popoverAQIDescription,
    'popover-measurements': popoverMeasurements,
  },
  props: {
    deviceId: String,
    permissions: Array,
  },
  computed: {
    id: function () {
      return this.deviceId || ''
    },
    lastTelemetryTimestamp: function () {
      return this.$store.getters['devices/getLastTelemetryTimestampByDeviceId'](
        this.id
      )
    },
    sensorReadings: function () {
      return this.$store.getters['devices/getSensorReadingsByDeviceId'](this.id)
    },
    currentReading: function () {
      let cr = { aqi: '- -', raw: '- -' }
      if (activeLastDay(this.lastTelemetryTimestamp)) {
        const measType = this.$store.getters['map/getMeasurementType']
        if (measType == measurementTypes.pm25) {
          cr.aqi = getMeasurementByType(
            this.sensorReadings,
            'pm2.5 aqi',
            undefined
          )
          cr.raw = getMeasurementByType(this.sensorReadings, 'pm2.5', undefined)
        }
        if (measType == measurementTypes.pm10) {
          cr.aqi = getMeasurementByType(
            this.sensorReadings,
            'pm10 aqi',
            undefined
          )
          cr.raw = getMeasurementByType(this.sensorReadings, 'pm10', undefined)
        }
      }
      return cr
    },
  },
  methods: {
    // This is used by the parent component refresh
    refresh: function () {
      this.$refs.averages.loadData()
      this.$refs.percentChanged.loadData()
      this.$store.dispatch('devices/getLatestTelemetryAndUpdate', this.deviceId)
    },
  },
}
</script>
