<template>
  <div>
    <v-menu offset-y v-model="isMenuOpened">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="menu-dropdown black--text"
          text
          v-bind="attrs"
          v-on="on"
          data-cy="devicePopoverDropdown"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list v-if="isMenuOpened" data-cy="devicePopoverMenuOptions">
        <!-- Device Info -->
        <v-list-item
          v-if="canPerformAction('allowDeviceInfo')"
          @click.stop="openInfo"
          link
          data-cy="deviceInfoActivator"
        >
          <v-icon>mdi-information</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceInfo') }}
          </v-list-item-title>
        </v-list-item>
        <!-- Device Settings -->
        <v-list-item
          v-if="canPerformAction('allowMenuSettings')"
          @click.stop="openSettings"
          link
          data-cy="deviceSettingsActivator"
        >
          <v-icon>mdi-settings-box</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceSettings') }}
          </v-list-item-title>
        </v-list-item>
        <!-- Device Alerts -->
        <v-list-item
          v-if="canPerformAction('allowMenuAlertSetup')"
          @click.stop="openAlerts"
          link
          data-cy="deviceAlertsActivator"
        >
          <v-icon>mdi-bell</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceAlerts') }}
          </v-list-item-title>
        </v-list-item>
        <!-- Device Sharing -->
        <v-list-item
          v-if="canPerformAction('allowMenuSharing')"
          @click.stop="openSharing"
          link
          data-cy="deviceSharing"
        >
          <v-icon>mdi-share-variant</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceSharing') }}
          </v-list-item-title>
        </v-list-item>
        <!-- History -->
        <v-list-item
          v-if="canPerformAction('allowMenuHistory')"
          @click.stop="openHistory"
          link
          data-cy="historyActivator"
        >
          <v-icon>mdi-chart-line</v-icon>
          <v-list-item-title>
            {{ $t('map.popover.menu.deviceHistory') }}
          </v-list-item-title>
        </v-list-item>
        <!-- View on Map -->
        <view-on-map-menu
          v-if="canPerformAction('allowMenuViewOnMap')"
          :deviceId="deviceId"
          :permissions="permissions"
          :accessLevel="accessLevel"
        />
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { access, canAccess } from '../../services/device-access'
import ViewOnMapMenu from './viewOnMapMenu'
import { getPermissionsByDeviceId } from '../../services/subscriptions'
import { SlugsEnum } from '../../permissions/SlugsEnum'

export default {
  name: 'DeviceActionMenu',
  props: {
    deviceId: String,
    accessLevel: Number,
  },
  data() {
    return {
      isMenuOpened: false,
    }
  },
  components: {
    'view-on-map-menu': ViewOnMapMenu,
  },
  methods: {
    canPerformAction(slugName) {
      switch (slugName) {
        case 'allowDeviceInfo':
          //As long as you own the device
          return canAccess(access.FULL, this.accessLevel)
        case 'allowMenuSettings':
          //As long as you own the device
          return (
            canAccess(access.FULL, this.accessLevel)
          )
        case 'allowMenuAlertSetup':
          //As long as you own the device
          //AND the device has the permission
          return (
            canAccess(access.FULL, this.accessLevel) &&
            this.hasDevicePermission(SlugsEnum.MenuAlertSetup)
          )
        case 'allowMenuSharing':
          //As long as you own the device
          //AND the device has the permission
          return (
            canAccess(access.FULL, this.accessLevel) &&
            this.hasDevicePermission(SlugsEnum.MenuSharing)
          )
        case 'allowMenuHistory':
          //As long as you own the device OR the device has been shared with you
          //AND you as the user have the permission
          //AND the device is either shared with you OR if you own it then the device has the permission
          return (
            canAccess(access.SHARED, this.accessLevel) &&
            this.hasUserPermission(SlugsEnum.MenuHistory) &&
            (this.accessLevel === access.SHARED ||
              this.hasDevicePermission(SlugsEnum.MenuHistory))
          )
        case 'allowMenuViewOnMap':
          //As long as you own the device OR the device has been shared with you
          //AND you as the user have the permission
          //AND the device is either shared with you OR if you own it then the device has the permission
          return (
            canAccess(access.SHARED, this.accessLevel) &&
            this.hasUserPermission(SlugsEnum.MenuViewOnMap) &&
            (this.accessLevel === access.SHARED ||
              this.hasDevicePermission(SlugsEnum.MenuViewOnMap))
          )
      }
      return false
    },
    hasUserPermission(slugName) {
      return this.$permissions.isAllowed(slugName)
    },
    hasDevicePermission(slugName) {
      return this.permissions.includes(slugName)
    },
    openInfo() {
      this.openDialog('info')
    },
    openSettings() {
      this.openDialog('settings')
    },
    openAlerts() {
      this.openDialog('alerts')
    },
    openSharing() {
      this.openDialog('sharing')
    },
    openHistory() {
      this.openDialog('history')
    },
    openDialog(dialog) {
      this.$emit('openDialog', {
        dialog,
        deviceId: this.deviceId,
      })
    },
  },
  computed: {
    permissions() {
      return getPermissionsByDeviceId(this.deviceId)
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-dropdown {
  min-width: 10px !important;
  padding: 0 0.4rem !important;
}
</style>
