<template>
  <base-dialog
    @close="close"
    :open="open"
    :error="error"
    :title="$t('shareDevice.title')"
    :persistentOverlay="true"
    data-cy="deviceSharingDialog"
  >
    <template v-slot:header>
      <v-card-title>
        <v-icon class="header-icon">mdi-share-variant</v-icon>
        {{ $t('shareDevice.title') }}
      </v-card-title>
      <v-card-subtitle class="hint-text">
        {{ maximumNumberCollaborators }}
      </v-card-subtitle>
    </template>
    <v-card flat>
      <div class="hint-text">
        {{ $t('shareDevice.ensureAccountInstruction') }}
      </div>
      <v-row align="center" :class="{ disabled: addDisabled }">
        <div class="collaborator-email-wrapper">
          <v-text-field
            class="collaborator-email"
            v-model="newCollaboratorEmail"
            v-bind:label="$t('shareDevice.emailLabel')"
            :rules="[rules.email]"
            data-cy="collaboratorEnterEmail"
          ></v-text-field>
        </div>
        <v-spacer></v-spacer>
        <v-tooltip :disabled="!showUpgradeTooltip" bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                text
                class="btn-add"
                :class="{ disabled: addDisabled }"
                :disabled="addDisabled"
                @click="addCollaborator"
                data-cy="addCollaboratorButton"
                >{{ $t('buttons.add') }}</v-btn
              >
            </div>
          </template>
          <span>{{ $t('shareDevice.upgradeTooltip') }}</span>
        </v-tooltip>
      </v-row>
    </v-card>
    <v-simple-table v-if="collaborators.length > 0" class="collabTable">
      <template v-slot:default>
        <thead>
          <tr>
            <th scope="col" class="text-left">{{ $t('shareDevice.email') }}</th>
            <th scope="col" class="text-left">
              {{ $t('shareDevice.status') }}
            </th>
            <th scope="col" class="text-left">
              {{ $t('shareDevice.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="collaborator in collaborators"
            :key="collaborator.email"
            data-cy="sharedEmail"
          >
            <td>{{ trim(collaborator.email, 24) }}</td>
            <td data-cy="sharedStatus">
              <v-icon
                :title="
                  collaborator.status == 'active' ? 'Active' : 'No Account'
                "
              >
                {{
                  collaborator.status == 'active' ? 'mdi-check' : 'mdi-alert'
                }}
              </v-icon>
            </td>
            <td class="td-actions">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="black--text actions-button"
                    text
                    v-on="on"
                    data-cy="sharedActions"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    data-cy="deleteCollaborator"
                    @click="removeCollaborator(collaborator)"
                  >
                    <v-icon>mdi-delete</v-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="notShared hint-text" v-else>
      {{ $t('shareDevice.notShared') }}
    </div>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="close"
        class="primary white--text large"
        text
        data-cy="deviceSharingClose"
        >{{ $t('buttons.done') }}</v-btn
      >
    </template>
  </base-dialog>
</template>

<script>
import { checkStatus } from '../../api/services/utils'
import Dialog from './Dialog.vue'
import { SlugsEnum } from '../../permissions/SlugsEnum'

const PERMISSION_2_COLLABORATOR_NUMBER = 2
const PERMISSION_10_COLLABORATOR_NUMBER = 10
const PERMISSION_20_COLLABORATOR_NUMBER = 20
const MSG_TIMEOUT_MS = 10000
const VALID_EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export default {
  name: 'DeviceSharingDialog',
  props: ['open', 'deviceId', 'permissions'],
  mixins: [],
  components: {
    'base-dialog': Dialog,
  },
  data() {
    return {
      newCollaboratorEmail: '',
      showFailedMessage: false,
      showNoAccountMessage: false,
      rules: {
        empty: (value) => value === null || value.length === 0,
        required: (value) => !!value || this.$t('validation.required'),
        email: (value) => {
          return (
            value === '' ||
            VALID_EMAIL_REGEX.test(value) ||
            this.$t('validation.invalidEmail')
          )
        },
      },
      collaborators: [],
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    open() {
      if (this.open) {
        this.init()
      }
    },
  },
  computed: {
    hasUpTo10Collaborators() {
      return this.permissions.includes(SlugsEnum.CollaboratorsWriteLessThan11)
    },
    hasUpTo20Collaborators() {
      return this.permissions.includes(SlugsEnum.CollaboratorsWriteLessThan21)
    },
    hasUpTo2Collaborators() {
      return this.permissions.includes(SlugsEnum.CollaboratorsWriteLessThanOrEqualTo2)
    },
    maxCollaborators() {
      let maxCollabs = 0;
      if (this.hasUpTo20Collaborators) {
        maxCollabs = PERMISSION_20_COLLABORATOR_NUMBER
      }
      if (this.hasUpTo10Collaborators) {
        maxCollabs = PERMISSION_10_COLLABORATOR_NUMBER
      }
      if (this.hasUpTo2Collaborators) {
        maxCollabs = PERMISSION_2_COLLABORATOR_NUMBER
      }
      return maxCollabs
    },
    showUpgradeTooltip() {
      //Only show the upgrade tooltip if max collabs have been added for each permission (2, 10, 20)
      return (
        !this.hasUpTo20Collaborators &&
        this.collaborators.length >= this.maxCollaborators
      )
    },
    addDisabled() {
      return (
        !VALID_EMAIL_REGEX.test(this.newCollaboratorEmail) ||
        this.collaborators.length >= this.maxCollaborators
      )
    },
    error() {
      let err

      if (this.showNoAccountMessage) {
        err = this.$t('shareDevice.userNoAccount')
      }
      if (this.showFailedMessage) {
        err = this.$t('shareDevice.failedToAdd')
      }

      return err
    },
    maximumNumberCollaborators() {
      return this.$t('shareDevice.subtitle', {
        numberOfCollaborators: this.maxCollaborators,
      })
    },
  },
  methods: {
    init() {
      if (this.deviceId) {
        this.getCollaborators()
      }
    },
    close() {
      this.newCollaboratorEmail = ''
      this.$emit('close')
    },
    trim(str, maxlen) {
      if (str.length <= maxlen) return str

      str = str.substring(0, maxlen)
      return str + '...'
    },
    async getCollaborators() {
      const resp = await this.$api.getDeviceCollaborators(this.deviceId)
      if (checkStatus(resp)) {
        const body = await resp.json()
        this.collaborators = []
        for (let i = 0; i < body.length; i++) {
          let collaborator = {}
          collaborator.email = body[i].email
          collaborator.status = body[i].status
          this.collaborators.push(collaborator)
        }
      }
    },
    async addCollaborator() {
      const userInfo = {
        email: this.newCollaboratorEmail.toLowerCase(),
      }
      try {
        const resp = await this.$api.postShareDevice(userInfo, this.deviceId)
        this.newCollaboratorEmail = ''
        if (checkStatus(resp)) {
          const body = await resp.json()
          if (body && body.status === 'pending') {
            this.showNoAccountMessage = true
            setTimeout(() => {
              this.showNoAccountMessage = false
            }, MSG_TIMEOUT_MS)
          }
          this.getCollaborators()
        } else {
          this.displayFailedMessage()
        }
      } catch {
        this.displayFailedMessage()
      }
    },
    displayFailedMessage() {
      this.showFailedMessage = true
      setTimeout(() => {
        this.showFailedMessage = false
      }, MSG_TIMEOUT_MS)
    },
    async removeCollaborator(collaborator) {
      const resp = await this.$api.deleteDeviceCollaborator(
        collaborator.email,
        this.deviceId
      )
      if (checkStatus(resp)) {
        this.getCollaborators()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    padding: 24px;
    min-height: 350px;

    &--flat {
      min-height: 0;
    }
    .v-input {
      padding-top: 24px;
    }
  }
  .collabTable {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (min-width: 496px) {
    @media (min-height: 770px) {
      .collabTable {
        max-height: 300px;
        overflow-y: auto;
      }
    }
  }
  @media (max-width: 496px) {
    .collabTable {
      margin-top: 1rem;
    }
  }
  .notShared {
    padding: 18px 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    color: rgba(0, 0, 0, 0.6);
  }
  .v-card__title {
    font-weight: 300;
  }
  .large.v-btn {
    min-width: 120px !important;
    @media (min-width: 496px) {
      min-width: 160px !important;
    }
  }
  .hint-text {
    font-size: 12px;
    opacity: 0.76;
  }
  .row {
    margin-left: unset;
    margin-right: unset;
  }
  .hint-text.red-font {
    color: red;
  }
  .hint-text-button {
    margin-left: 16px;
  }
  .errorMessage {
    font-size: 12px;
    color: red;
    text-align: right;
    padding-right: 16px;
  }
  .collaborator-email-wrapper {
    width: 100% !important;
    @media (min-width: 496px) {
      width: 70% !important;
    }
  }
  .btn-add {
    @media (max-width: 496px) {
      margin-left: auto;
    }
    max-width: 104px;
    background-color: #4fb74a;
    color: #ffffff;
    min-width: 104px !important;
  }
  .btn-add.disabled {
    border-color: grey;
    background-color: #a2a2a2 !important;
  }
  .disabled {
    cursor: not-allowed;
  }
  .td-actions {
    width: 1px;
    padding-right: 0;
  }
  .actions-button {
    padding: 0;
    min-width: 40px;
  }
}
</style>
