<template>
  <div class="measurement-container-large">
    <popover-measurement
      :reading="readingPm"
      :label="$t(`readings.${pmReadingType}`)"
      :unitsLabel="pmReadingUnitLabel"
      imageSrc="/img/measurements/pm.svg"
      :largeMeasurement="true"
    />
    <popover-measurement
      :reading="readingTemp"
      :label="$t(`readings.temp`)"
      :unitsLabel="$t(`units.${tempReadingType}`)"
      imageSrc="/img/measurements/temp.svg"
      :largeMeasurement="true"
    />
    <popover-measurement
      :reading="readingHumidity"
      :label="$t(`readings.rh`)"
      :unitsLabel="$t('units.percent')"
      imageSrc="/img/measurements/rh.svg"
      :largeMeasurement="true"
    />
  </div>
</template>

<script>
import popoverMeasurement from '../popoverMeasurement.vue'
import { convertArrayToObject } from '../../../../services/data-conversion'
import {
  getMeasurementTypes,
  getReadingPM,
  getReadingValue,
  getReadingTemperature,
  getTempReadingType,
} from '../../utils'
import { UnitOfMeasure, UnitOfMeasureProperties } from '@fusion/units'

export default {
  props: {
    deviceId: String,
    sensorReadings: Array,
  },
  components: {
    'popover-measurement': popoverMeasurement,
  },
  computed: {
    deviceType: function () {
      return this.$store.getters['devices/getDeviceTypeByDeviceId'](this.deviceId)
    },
    readings: function () {
      return convertArrayToObject(this.sensorReadings, 'name')
    },
    pmReadingType: function () {
      const measType = this.$store.getters['map/getMeasurementType']
      return getMeasurementTypes(measType)
    },
    pmReadingUnit: function () {
      return this.$store.state.userProfile?.settings.pmUnit || UnitOfMeasure.MICROGRAMS_PER_CUBIC_METER
    },
    pmReadingUnitLabel: function () {
      return UnitOfMeasureProperties[this.pmReadingUnit]?.code || ''
    },
    tempReadingType: function () {
      const tempUnit = this.$store.state.userProfile?.settings.tempUnit
      return getTempReadingType(tempUnit)
    },
    readingPm: function () {
      return getReadingPM(
        this.readings,
        this.pmReadingUnit,
        this.deviceType,
        this.pmReadingType
      )
    },
    readingTemp: function () {
      return getReadingTemperature(this.readings, this.tempReadingType)
    },
    readingHumidity: function () {
      return getReadingValue(this.readings, 'Relative Humidity')
    },
  },
}
</script>
<style lang="scss" scoped>
.measurement-container-large {
  margin-top: 16px;

  @media (max-width: 460px) {
    padding-bottom: 40px;
  }
}
</style>
