<template>
  <v-row
    no-gutters
    class="percent-change"
    :class="{ hidden: percentChange === null }"
    data-cy="percentChange"
  >
    <v-icon v-if="percentChange > 0" class="arrow-icon text--primary">
      mdi-arrow-up-bold
    </v-icon>
    <v-icon v-if="percentChange < 0" class="arrow-icon text--primary">
      mdi-arrow-down-bold
    </v-icon>
    <span v-if="percentChange !== 0" class="change">
      {{ Math.abs(percentChange) }}%
    </span>
    <span v-if="percentChange === 0">
      {{ $t('map.popover.percentChange.noChange') }}
    </span>
    <span class="message">
      {{ $t('map.popover.percentChange.last24Hours') }}
    </span>
  </v-row>
</template>

<script>
import { measurementTypes } from '../../../store/map/utils'
import { DEVICE_UPDATE_INTERVAL_MS } from '../../../services/device-metadata'

export default {
  props: {
    deviceId: String,
  },
  data() {
    return {
      loadDataInterval: null,
    }
  },
  computed: {
    id: function () {
      return this.deviceId || ''
    },
    percentChange: function () {
      const measType = this.$store.getters['map/getMeasurementType']

      const perBlob = this.$store.getters[
        'devices/getPercentChangedByDeviceId'
      ](this.id)
      if (!perBlob) return null
      if (measType === measurementTypes.pm25) {
        if (perBlob['pm2x5_aqi_percent_change'] == 0) return 0
        if (perBlob['pm2x5_aqi_percent_change'] != null) {
          return Math.round(perBlob['pm2x5_aqi_percent_change'] * 100)
        }
        if (perBlob['pm2x5_aqi_start'] == 0 && perBlob['pm2x5_aqi_end'] == 0) {
          return 0
        }
      }

      if (measType === measurementTypes.pm10) {
        if (perBlob['pm10_aqi_percent_change'] == 0) return 0
        if (perBlob['pm10_aqi_percent_change'] != null) {
          return Math.round(perBlob['pm10_aqi_percent_change'] * 100)
        }
        if (perBlob['pm10_aqi_start'] == 0 && perBlob['pm10_aqi_end'] == 0) {
          return 0
        }
      }

      return null
    },
  },
  methods: {
    startLoadDataInterval: function () {
      this.loadData()
      if (this.loadDataInterval === null)
        this.loadDataInterval = setInterval(
          this.loadData,
          DEVICE_UPDATE_INTERVAL_MS
        )
    },
    loadData: function () {
      if (this.deviceId) {
        this.$store.dispatch(
          'devices/getPercentChangedAndUpdate',
          this.deviceId
        )
      }
    },
    stopLoadDataInterval: function () {
      if (this.loadDataInterval !== null) {
        clearInterval(this.loadDataInterval)
        this.loadDataInterval = null
      }
    },
  },
  mounted() {
    this.startLoadDataInterval()
  },
  destroyed() {
    this.stopLoadDataInterval()
  },
}
</script>

<style lang="scss" scoped>
.percent-change {
  opacity: 1;
  transition: opacity 500ms;
  height: 18px;
  margin-top: 12px;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 300;
  .arrow-icon {
    font-size: 16px;
  }
  span {
    margin-left: 4px;
    &:first-child {
      margin-left: 3px;
    }
  }
  .change {
    font-weight: 600;
  }
  @media (max-width: 460px) {
    margin-top: 8px;
  }
}

.hidden {
  opacity: 0;
  transition: opacity 0ms;
}
</style>
