<template>
  <span>
    <span
     class="condition-icons-wrapper"
      v-if="canAccess(access.FULL, accessLevel)">
      <connection-status :deviceId="deviceId" />
      <battery-status :deviceId="deviceId" />
      <communication-status :deviceId="deviceId" />
      <offline-studies-status :deviceId="deviceId" />
      <live-studies-status :deviceId="deviceId" />
      <eol-alert :deviceId="deviceId" />
      <device-alerts :deviceId="deviceId" />
    </span>
    <span v-else class="not-available-text">
      {{ $t('manageDevices.notAvailable') }}
    </span>
  </span>
</template>

<script>
import { accessUtil } from '../../services/device-access'

import BatteryStatus from '../icons/BatteryStatus.vue'
import connectionStatus from '../devicePopover/title/connectionStatus.vue'
import CommunicationStatus from '../icons/CommunicationStatus.vue'
import OfflineStudiesStatus from '../icons/OfflineStudiesStatus.vue'
import LiveStudiesStatus from '../icons/LiveStudiesStatus.vue'
import eolAlert from '../devicePopover/title/eolAlert.vue'
import deviceAlerts from '../devicePopover/title/deviceAlerts.vue'

export default {
  name: 'ManageDevicesTableCondition',
  props: {
    deviceId: String,
    accessLevel: Number,
  },
  mixins: [accessUtil],
  components: {
    'battery-status': BatteryStatus,
    'connection-status': connectionStatus,
    'communication-status': CommunicationStatus,
    'eol-alert': eolAlert,
    'device-alerts': deviceAlerts,
    'offline-studies-status': OfflineStudiesStatus,
    'live-studies-status': LiveStudiesStatus,
  },
}
</script>

<style lang="scss" scoped>
.condition-icons-wrapper {
  display: flex;
}
</style>
