import { SlugsEnum } from '../permissions/SlugsEnum'

export default {
  simple: [
    { slug: SlugsEnum.DeveloperPanel, propertyName: 'allowDeveloperPanelAccess'},
    { slug: SlugsEnum.AdminPanel, propertyName: 'allowAdminPanelAccess' },
    {
      slug: SlugsEnum.SideBarDashboardExecute,
      propertyName: 'allowDashboardAccess',
    },
    {
      slug: SlugsEnum.SideBarDevicesExecute,
      propertyName: 'allowDevicesAccess',
    },
    {
      slug: SlugsEnum.SideBarDataServicesExecute,
      propertyName: 'allowDataServicesAccess',
    },
    {
      slug: SlugsEnum.SideBarStudyManagerExecute,
      propertyName: 'allowStudyManagerAccess',
    },
    { slug: SlugsEnum.GasReadCO, propertyName: 'allowGasReadCO' },
    { slug: SlugsEnum.GasReadO3, propertyName: 'allowGasReadO3' },
    { slug: SlugsEnum.GasReadTVOC, propertyName: 'allowGasReadTVOC' },
    { slug: SlugsEnum.GasReadSO2, propertyName: 'allowGasReadSO2' },
    { slug: SlugsEnum.GasReadNO2, propertyName: 'allowGasReadNO2' },
    { slug: SlugsEnum.GasReadCH2O, propertyName: 'allowGasReadCH2O' },
    { slug: SlugsEnum.GasExportMonth, propertyName: 'allowGasExportMonth' },
    { slug: SlugsEnum.GasExportCO, propertyName: 'allowGasExportCO' },
    { slug: SlugsEnum.GasExportO3, propertyName: 'allowGasExportO3' },
    { slug: SlugsEnum.GasExportTVOC, propertyName: 'allowGasExportTVOC' },
    { slug: SlugsEnum.GasExportSO2, propertyName: 'allowGasExportSO2' },
    { slug: SlugsEnum.GasExportNO2, propertyName: 'allowGasExportNO2' },
    { slug: SlugsEnum.GasExportCH2O, propertyName: 'allowGasExportCH2O' },
    { slug: SlugsEnum.DeviceAddMoreThan1, propertyName: 'allowDeviceAdd' },
    { slug: SlugsEnum.DeviceAddUpTo1, propertyName: 'allowDeviceAddUpTo1' },
    {
      slug: SlugsEnum.DashboardCreateWidget,
      propertyName: 'allowDashboardCreateWidget',
    },
    { slug: SlugsEnum.MapViewPin, propertyName: 'allowMapViewPin' },
    { slug: SlugsEnum.MenuAlertSetup, propertyName: 'allowMenuAlertSetup' },
    { slug: SlugsEnum.MenuHistory, propertyName: 'allowMenuHistory' },
    { slug: SlugsEnum.MenuSettings, propertyName: 'allowMenuSettings' },
    { slug: SlugsEnum.MenuSharing, propertyName: 'allowMenuSharing' },
    { slug: SlugsEnum.MenuViewOnMap, propertyName: 'allowMenuViewOnMap' },
    {
      slug: SlugsEnum.AlertSetupCreateAlertCH2O,
      propertyName: 'allowAlertSetupCreateAlertCH2O',
    },
    {
      slug: SlugsEnum.AlertSetupCreateAlertCO,
      propertyName: 'allowAlertSetupCreateAlertCO',
    },
    {
      slug: SlugsEnum.AlertSetupCreateAlertNO2,
      propertyName: 'allowAlertSetupCreateAlertNO2',
    },
    {
      slug: SlugsEnum.AlertSetupCreateAlertO3,
      propertyName: 'allowAlertSetupCreateAlertO3',
    },
    {
      slug: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
      propertyName: 'allowAlertSetupCreateAlertPM10_PM25_Temp_RH',
    },
    {
      slug: SlugsEnum.AlertSetupCreateAlertSO2,
      propertyName: 'allowAlertSetupCreateAlertSO2',
    },
    {
      slug: SlugsEnum.AlertSetupCreateAlertTVOC,
      propertyName: 'allowAlertSetupCreateAlertTVOC',
    },
    {
      slug: SlugsEnum.CollaboratorsWriteLessThan11,
      propertyName: 'allowCollaboratorsWriteLessThan11',
    },
    {
      slug: SlugsEnum.CollaboratorsWriteLessThan21,
      propertyName: 'allowCollaboratorsWriteLessThan21',
    },
    {
      slug: SlugsEnum.CollaboratorsWriteLessThanOrEqualTo2,
      propertyName: 'allowCollaboratorsWriteLessThanOrEqualTo2',
    },
    {
      slug: SlugsEnum.CleaningIntervalWriteAll,
      propertyName: 'allowCleaningIntervalWriteAll',
    },
    {
      slug: SlugsEnum.HistoryChartExecuteDataMonth,
      propertyName: 'allowHistoryChartExecuteDataMonth',
    },
    {
      slug: SlugsEnum.HistoryChartExecuteDataThreeMonths,
      propertyName: 'allowHistoryChartExecuteDataThreeMonths',
    },
    {
      slug: SlugsEnum.HistoryChartExecuteExportMonth,
      propertyName: 'allowHistoryChartExecuteExportMonth',
    },
    {
      slug: SlugsEnum.HistoryChartExecuteExportThreeMonths,
      propertyName: 'allowHistoryChartExecuteExportThreeMonths',
    },
    {
      slug: SlugsEnum.LoggingIntervalWriteAll,
      propertyName: 'allowLoggingIntervalWriteAll',
    },
    {
      slug: SlugsEnum.LoggingIntervalWriteGreaterThanOrEqualTo15,
      propertyName: 'allowLoggingIntervalWriteGreaterThanOrEqualTo15',
    },
    {
      slug: SlugsEnum.LoggingIntervalWriteGreaterThan1,
      propertyName: 'allowLoggingIntervalGreaterThan1',
    },
    {
      slug: SlugsEnum.UserCalibrationWrite,
      propertyName: 'allowUserCalibrationWrite',
    },
    {
      slug: SlugsEnum.ReadConsecutiveZerosWarning,
      propertyName: 'readConsecutiveZerosWarning',
    },
    {
      slug: SlugsEnum.ExternalApiV2SecretButton,
      propertyName: 'externalApiV2SecretButton',
    },
    {
      slug: SlugsEnum.ExternalApiV3ApigeeButton,
      propertyName: 'externalApiV3ApigeeButton',
    },
    {
      slug: SlugsEnum.BulkDeviceRegistration,
      propertyName: 'allowBulkDeviceRegistration',
    },
    {
      slug: SlugsEnum.LogInAs,
      propertyName: 'allowLogInAs',
    },
    {
      slug: SlugsEnum.AlertsWrite,
      propertyName: 'alertsWrite',
    },
    {
      slug: SlugsEnum.AlertsWriteUpTo1,
      propertyName: 'alertsWriteUpTo1',
    }
  ],
}
