<template>
  <span>
    <GmapMap
      ref="gmap"
      :center="center"
      :zoom="zoom"
      map-type-id="terrain"
      style="height: 100%; width: 100%"
      :options="{
        clickableIcons: false,
        streetViewControl: false,
        mapTypeControl: true,
        fullscreenControl: false,
        minZoom: 4,
        maxZoom: 24,
        restriction: {
          latLngBounds: {
            north: 85,
            south: -85,
            west: -180,
            east: 180,
          },
        },
        styles: [
          {
            featureType: 'poi',
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 30,
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.icon',
            stylers: [
              {
                lightness: 55,
              },
            ],
          },
        ],
      }"
      @center_changed="saveMapLocation($event)"
      @zoom_changed="updateZoom($event)"
      @click="closeInfoWindow"
    >
      <map-pin
        v-for="(coords, deviceId, index) in devices"
        :key="deviceId"
        :deviceId="deviceId"
        :clickable="clickable"
        :ref="setPinRef(deviceId)"
        :moving="setPinIsMoving(deviceId)"
        :zIndex="setPinZIndex(deviceId, index)"
        :opacity="setPinOpacity(deviceId)"
        data-cy="mapPin"
        @clicked="toggleInfoWindow($event)"
        @closed="closePin($event)"
      />
      <GmapInfoWindow
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="closeInfoWindow"
      >
        <device-popover :deviceId="currentPinId" @close="closeInfoWindow" />
      </GmapInfoWindow>
      <map-settings-dialog
        :open="dialogs.mapSettings"
        @close="dialogs.mapSettings = false"
      />
    </GmapMap>
    <v-btn
      class="map-settings-button"
      color="white"
      small
      @click="toggleMapSettings()"
      data-cy="mapSettingsButton"
      min-width="40"
      height="40"
      width="40"
    >
      <v-icon>mdi-settings</v-icon>
    </v-btn>
  </span>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'

import { locationGetter } from '../services/location'
import Vue from 'vue'
import MapSettingsDialog from '../components/dialogs/MapSettings'
import MapPin from '../components/MapPin.vue'
import DevicePopover from '../components/devicePopover/devicePopover.vue'
import { deviceModels } from '../store/subscriptions/utils'

Vue.component('map-settings-dialog', MapSettingsDialog)
Vue.component('map-pin', MapPin)
Vue.component('device-popover', DevicePopover)

export default {
  mixins: [locationGetter],
  data() {
    return {
      center: { lat: 30, lng: -87 },
      infoWindowPos: null,
      infoWinOpen: false,
      currentPinId: null,
      lastPinId: null,
      topZIndex: Object.keys(this.$store.state.devices.coords).length,
      pinsHeightMap: {},
      infoOptions: {
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: -4,
          height: -35,
        },
      },
      infoText: '<strong>Marker 3</strong>',
      dialogs: {
        mapSettings: false,
      },
    }
  },
  computed: {
    google: gmapApi,
    zoom() {
      return this.$store.state.map.location.zoom
    },
    inMovingMode() {
      return this.$store.state.map.movingMode
    },
    movingPin() {
      return this.$store.state.map.deviceToMove?.deviceId
    },
    clickable() {
      return !this.inMovingMode
    },
    devices() {
      return this.$store.state.devices.coords
    },
    allDeviceModels(){
      return this.$store.getters['devicemodels/getModels']
    }
  },
  destroyed() {
    if (this.$store.state.map.deviceViewMode) {
      this.$store.dispatch('map/stopDeviceViewMode')
    }
  },
  watch: {
    inMovingMode(isMoving) {
      if (isMoving) {
        this.setMapToMovingMode()
      }
    },
    allDeviceModels(newVal, oldVal){
      if (oldVal.length === 0 && newVal.length > 0){
        this.ensureDeviceModelsPopulated()
      }
    }
  },
  mounted() {
    if (this.$store.state.map.deviceViewMode) {
      const { deviceId, deviceType } = this.$store.state.map.deviceToView
      const coords =
        this.$store.getters['devices/getCoordsByDeviceId'](deviceId)
      this.center = coords
      this.setLocation(coords)
      this.toggleInfoWindow({ coords, deviceId, deviceType })
    } else {
      this.getLocation((coords) => {
        if (coords) {
          this.center = this.$store.state.map.movingMode
            ? this.$store.getters['devices/getCoordsByDeviceId'](
                this.$store.state.map.deviceToMove?.deviceId
              )
            : coords
        }
      })
    }

    if (this.inMovingMode) {
      this.setMapToMovingMode()
    }

    this.setControls()
    this.ensureDeviceModelsPopulated()
  },
  methods: {
    updateZoom(event) {
      this.$store.dispatch('map/setZoom', event)
    },
    toggleMapSettings() {
      this.dialogs.mapSettings = !this.dialogs.mapSettings
    },
    ensureDeviceModelsPopulated() {
      const selectedModels = this.$store.getters['map/getDeviceModels']
      if (!selectedModels || selectedModels.length <= 0) {
        this.$store.dispatch('map/updateSettings', {
          deviceModels: this.allDeviceModels.filter(model => model !== deviceModels.SMART_BRIDGE),
        })
      }
    },
    closePin(event) {
      if (event == this.currentPinId) {
        this.closeInfoWindow()
      }
    },
    closeDeviceSettings(marker) {
      this.closeInfoWindow()
      this.toggleInfoWindow(marker)
    },
    toggleInfoWindow(marker) {
      this.infoWindowPos = marker.coords

      //check if its the same marker that was selected if yes toggle
      if (this.currentPinId === marker.deviceId) {
        this.infoWinOpen = !this.infoWinOpen
      }

      // if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.lastPinId = this.currentPinId
        this.currentPinId = marker.deviceId
      }

      // refresh device data if infowindow is open
      if (this.infoWinOpen) {
        this.getDeviceData(marker)
      }
    },
    closeInfoWindow() {
      if (this.infoWinOpen) {
        this.lastPinId = this.currentPinId
        this.currentPinId = null
        this.infoWinOpen = false
      }
    },
    async setControls() {
      const map = await this.$refs.gmap.$mapPromise
      map.setOptions({
        zoomControlOptions: {
          position: this.google.maps.ControlPosition.LEFT_TOP,
        },
      })
    },
    async setMapToMovingMode() {
      const device = this.$store.state.map.deviceToMove
      if (device) {
        this.dialogs.mapSettings = false
        this.infoWinOpen = false

        const map = await this.$refs.gmap.$mapPromise
        map.panTo(device.coords)
      }
    },
    saveMapLocation(event) {
      const coords = {
        lat: event.lat(),
        lng: event.lng(),
      }

      this.setLocation(coords)
    },
    getDeviceData(device) {
      const { deviceId, deviceType } = device
      this.$store.dispatch('devices/getTelemetryAveragesAndUpdate', deviceId)
      if (
        [this.$api.deviceType.USER, this.$api.deviceType.SHARED].includes(
          deviceType
        )
      ) {
        this.$store.dispatch('devices/getDeviceDetailsAndUpdate', deviceId)
      }
      this.$store.dispatch('devices/getLatestTelemetryAndUpdate', deviceId)
      this.$store.dispatch('devices/getPercentChangedAndUpdate', deviceId)
    },
    setPinRef(deviceId) {
      if (this.inMovingMode && deviceId === this.movingPin) {
        return 'movingPin'
      }
      return deviceId
    },
    setPinIsMoving(deviceId) {
      return this.inMovingMode && deviceId === this.movingPin
    },
    setPinZIndex(deviceId, index) {
      if (this.inMovingMode && deviceId === this.movingPin) {
        return this.google.maps.Marker.MAX_ZINDEX + 1
      }

      if (deviceId === this.currentPinId) {
        return this.google.maps.Marker.MAX_ZINDEX
      }

      if (deviceId === this.lastPinId) {
        this.pinsHeightMap[deviceId] = ++this.topZIndex
        this.lastPinId = null
        return this.pinsHeightMap[deviceId]
      }

      return index
    },
    setPinOpacity(deviceId) {
      if (this.inMovingMode) {
        if (deviceId === this.movingPin) {
          return 1
        }
        return 0.56
      }
      return 1
    },
  },
}
</script>
<style lang="scss">
.gm-style-iw-t {
  .gm-style-iw-c {
    padding: 0 !important;
    width: 396px !important;
    // Prevent popover width getting too narrow at phone resolutions
    @media (max-width: 900px) {
      max-width: 376px !important;
    }
    @media (max-width: 460px) {
      max-width: 326px !important;
    }
    @media (max-width: 400px) {
      max-width: 300px !important;
    }
    .gm-style-iw-d {
      overflow-x: hidden !important;
      width: 396px !important;
      @media (max-width: 900px) {
        max-width: 376px !important;
      }
      @media (max-width: 460px) {
        max-width: 326px !important;
      }
      @media (max-width: 400px) {
        max-width: 300px !important;
      }
    }
  }
  button.gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
    display: none !important;
  }
}
.gm-style-iw-t:after {
  background: #ffffff !important;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar {
  // -webkit-appearance: unset !important;
  width: 0 !important;
}

// hides the Google logo on bottom left of screen
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.map-settings-button {
  position: absolute;
  left: 10px;
  top: 160px;
  color: #666666 !important;

  &:hover {
    color: black !important;
  }
  &:hover:before {
    background-color: transparent;
  }
}

.map-zoom-button {
  position: absolute;
  left: 10px;
  top: 210px;
  color: #666666 !important;

  &:hover {
    color: black !important;
  }
  &:hover:before {
    background-color: transparent;
  }
}
</style>
